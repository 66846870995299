@use "mixins";
@import "colors";

.swiper-slide {
  min-width: fit-content !important;
  display: grid;
  place-items: center;
}

.slider-small {
  background: $blue;
  padding: 150px 0;

  .slider-inner {
    position: relative;
    width: 570px;
    height: 480px;
    background: $white;
    color: $black;
    padding: 115px 48px 55px 48px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mixins.mobile {
      height: 590px;
      width: 100%;
    }

    svg {
      position: absolute;
      top: 23px;
    }

    .line {
      width: 100%;
      background-color: $black;
      height: 3px;
      margin-bottom: 28px;
    }

    span {
      color: $blue;
    }
  }
}
