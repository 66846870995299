@use "mixins";
@import "colors";

.hero {
  width: 100%;
  height: 920px;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  -webkit-justify-content: flex-end;

  h2 {
    font-weight: 500;
  }

  @include mixins.tablet {
    display: inline-block;
    height: 800px;
    top: 0;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    background: rgba($blue, 0.5);
    height: 100%;
    -webkit-clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
    z-index: -1;

    @include mixins.tablet {
      width: 100%;
      -webkit-clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @include mixins.tablet {
      display: block;
      height: 800px;
    }
  }

  .hero-text {
    @include mixins.desktop {
      display: flex;
      flex-direction: column;
      width: 600px;
    }

    @include mixins.big-desktop {
      width: 700px;
    }

    @include mixins.tablet {
      margin-top: 150px;
      padding-left: 24px;
      padding-right: 24px;
    }

    h2 {
      margin-top: 70px;

      @include mixins.tablet {
        margin-top: 30px;
      }
    }
  }
}
