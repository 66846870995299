@use "mixins";
@import "colors";

.timeline {
  background: $blue;
  position: relative;
  padding: 150px 100px;

  @include mixins.mobile {
    padding: 24px 24px;
  }

  .grid {
    display: grid;
    position: relative;
    grid-template-columns: repeat(4, 1fr);

    @include mixins.tablet {
      grid-template-columns: 1fr;
      gap: 50px;
    }

    .inner {
      position: relative;

      @include mixins.tablet {
        display: flex;
        gap: 30px;
        align-items: center;
      }
    }
  }

  p {
    font-size: 1rem;
    font-weight: 800;
    padding-right: 20px;

    @include mixins.tablet {
      padding-right: 0px;
    }
  }

  h2 {
    z-index: 2;
    position: relative;
    margin-bottom: 34px;
    display: grid;
    border: 3px solid $blue-grey;
    background: $blue;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    place-items: center;
    flex-shrink: 0;

    @include mixins.tablet {
      margin-bottom: 0px;
    }
  }

  .time-line {
    top: 45px;
    width: 100%;
    background-color: $blue-grey;
    z-index: 0;
    position: absolute;
    height: 6px;

    @include mixins.tablet {
      top: 80px;
      width: 6px;
      height: 100%;
      left: 47px;
    }
  }
}
